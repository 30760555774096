import { DateTime, Interval } from 'luxon'

console.snapshot = (...args) => console.log(JSON.parse(JSON.stringify(...args)))

const locations = {
    'wettstein': 0,
    'gundeli': 1,
    'klybeck': 2,
    'iselin': 3
}

const getLocationNumber = (location) => locations[location.toLowerCase()]

const parseDate = ds => {
    const parts = ds.split('T')
    const dateStr = parts[0]
    const timeStr = parts[1]

    const year = dateStr.slice(0, 4)
    const month = dateStr.slice(4, 6)
    const day = dateStr.slice(-2)
    const hour = timeStr.slice(0, 2)
    const minute = timeStr.slice(2, 4)

    const date = DateTime.utc(parseInt(year), parseInt(month), parseInt(day), parseInt(hour), parseInt(minute))

    let minutePercentage = (minute / 60) * 100
    minutePercentage = minutePercentage === 100 ? 0 : minutePercentage

    return {
        date,
        minutePercentage
    }
}

const fetchIcal = async () => {
    const response = await fetch('https://aeschengraben.spaces.nexudus.com/en/feeds/bookings?bid=1a49b27e-04ef-4eea-b7c7-d53b390849a1&guid=b19359f4-a3ea-4d99-9ae2-fc5bc02fa442&uid=43d011d8-1591-4410-9f76-1d94e406d413')
    const text = await response.text()
    const items = []
    let newItem = null

    for (const line of text.split('\n')) {
        if (line.indexOf('BEGIN:VEVENT') === 0) {
            newItem = {}
        }
        if (line.indexOf('END:VEVENT') === 0) {
            items.push(newItem)
            newItem = null
        }

        if (newItem !== null) {
            if (line.indexOf('DESCRIPTION:') === 0) {
                const summary = line.replace('DESCRIPTION:', '')
                newItem.summary = summary
            }
            if (line.indexOf('LOCATION:') === 0) {
                let location = null

                for (const availableLocation in locations) {
                    if (!location && line.toLowerCase().includes(availableLocation)) {
                        location = availableLocation
                    }
                }

                newItem.location = location
            }
            if (line.indexOf('DTSTART;') === 0) {
                newItem.start = line.replace('DTSTART;TZID=W. Europe Standard Time:', '').replace('\r', '')
            }
            if (line.indexOf('DTEND;') === 0) {
                newItem.end = line.replace('DTEND;TZID=W. Europe Standard Time:', '').replace('\r', '')
            }
        }
    }

    return items.filter(item => item.location !== null)
}

const getCalData = async (firstDay) => {
    const iCalData = await fetchIcal()
    // const iCalData = incoming

    /**
     * =================================================================
     * Define dates for first and second day (if not given as param)
     * =================================================================
     */

    if (!firstDay) {
        firstDay = DateTime.utc().set({ hour: 6, minute: 0, second: 0, millisecond: 0 })
    }

    const secondDay = DateTime.utc().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }).plus({ day: 1 })


    /**
     * =================================================================
     * FETCH ICAL FEED AND BUILD ENTRIES ARRAY
     * =================================================================
     */

    let entries = []

    for (const ie of iCalData) {
        const start = parseDate(ie.start)
        const end = parseDate(ie.end)

        let isFirstDay = start.date.hasSame(firstDay, 'day')
        let isSecondDay = start.date.hasSame(secondDay, 'day')

        if (!isFirstDay && !isSecondDay) {
            // Not same day
            continue
        }

        const interval = Interval.fromDateTimes(start.date, end.date)

        entries.push({
            id: ie.summary.replace(/\s+/g, '') + ie.location + start.date.toString() + end.date.toString(),
            start,
            end,
            interval,
            durationInMinutes: end.date.diff(start.date, 'minutes').toObject().minutes,
            title: ie.summary,
            location: ie.location,
            isFirstDay,
            isSecondDay
        })
    }

    /**
     * =================================================================
     * BUILD ARRAY PER LOCATION (COL)
     * =================================================================
     */

    const days = {
        first: {
            date: DateTime.utc().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }),
            locations: {
                wettstein: [],
                gundeli: [],
                klybeck: [],
                iselin: []
            }
        },
        second: {
            date: DateTime.utc().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }).plus({ day: 1 }),
            locations: {
                wettstein: [],
                gundeli: [],
                klybeck: [],
                iselin: []
            }
        }
    }

    for (const day of Object.values(days)) {
        let rowDate = day.date

        for (const location of Object.keys(locations)) {
            rowDate = day.date

            for (let i = 0; i < 60; i++) {
                const rowInterval = Interval.fromDateTimes(rowDate, rowDate.plus({ minutes: 15 }))

                let entry = entries.find(entry => {
                    return rowInterval.overlaps(entry.interval) &&
                        location.toLowerCase() === entry.location.toLowerCase()
                })

                if (!entry) {
                    entry = 0
                }

                const cols = new Array(4).fill(entry)

                rowDate = rowDate.plus({ minutes: 15 })

                day.locations[location].push(cols)
            }
        }
    }

    /**
     * =================================================================
     * MERGE ALL LOCATION ENTRIES INTO ONE ARRAY
     * =================================================================
     */

    for (const day of Object.values(days)) {
        const merged = []

        for (const location of Object.keys(locations)) {
            for (const i in day.locations[location]) {
                const row = day.locations[location][i]

                if (!merged[i]) {
                    merged[i] = row
                } else {
                    for (const mEi in merged[i]) {
                        const mE = merged[i][mEi]
                        const rowE = row[mEi]

                        if (mE === 0) {
                            merged[i][mEi] = rowE
                        } else if (rowE) {
                            const colPos = getLocationNumber(mE.location)
                            if (parseInt(mEi) > colPos) {
                                merged[i][mEi] = rowE
                            }
                        }
                    }
                }

            }
        }

        day.merged = merged
    }

    /*
     * =================================================================
     * RE-BALANCE MERGED ENTRIES
     * =================================================================
     */

    /*for (const day of Object.values(days)) {
        for (const rows of Object.values(day.locations)) {
            for (const row of rows) {
                const count = {};
                row.forEach(function (x) {
                    let id = x.id || 0
                    count[id] = (count[id] || 0) + 1;
                });

                const uniques = Object.keys(count).map(k => {
                    if (k === 0) {
                        return 0
                    }

                    return entries.find(e => e.id === k)
                })

                if (uniques.length === 2) {
                    row[0] = uniques[0]
                    row[1] = uniques[0]
                    row[2] = uniques[1]
                    row[3] = uniques[1]
                } else if (uniques.length === 3) {
                    row[0] = uniques[0]
                    row[1] = uniques[1]
                    row[2] = uniques[2]
                    row[3] = uniques[2]
                }
            }
        }
    }*/

    let entryCol = 0
    let entryWidth = 0

    let didChange = false
    let max = 100
    let loop = 0
    do {
        didChange = false

        for (const entry of entries) {
            const day = entry.isFirstDay ? days.first : days.second

            const entryRowIndices = day.merged.map((e, i) => e.some(iE => iE.id === entry.id) ? i : '').filter(String)
            const entryRowsResolved = entryRowIndices.map(entryRowIndex => {
                return {
                    entryRowIndex,
                    rowEntries: day.merged[entryRowIndex]
                }
            })

            entryRowsResolved
                .sort((a, b) => a.rowEntries.filter(e => e.id === entry.id).length - b.rowEntries.filter(e => e.id === entry.id).length)

            let entryColStartIndex = entryRowsResolved[0].rowEntries.findIndex(e => e.id === entry.id)
            let entryColWidth = entryRowsResolved[0].rowEntries.filter(e => e.id === entry.id).length

            if (entryCol != entryColStartIndex || entryWidth !== entryColWidth) {
                entryCol = entryColStartIndex
                entryWidth = entryColWidth
            }

            for (const entryRowIndex of entryRowIndices) {
                for (let colIndex in day.merged[entryRowIndex]) {
                    colIndex = parseInt(colIndex)
                    const e = day.merged[entryRowIndex][colIndex]

                    if (colIndex >= entryCol && colIndex <= entryCol + (entryWidth - 1)) {
                        day.merged[entryRowIndex][colIndex] = entry
                    } else if (e.id === entry.id) {
                        day.merged[entryRowIndex][colIndex] = 0
                    }
                }
            }

            let freeSpaceLeft = true
            let freeSpaceRight = true
            for (const entryRowIndex of entryRowIndices) {
                const entryRow = day.merged[entryRowIndex]

                if (entryRow[entryCol - 1] !== 0) {
                    if (!entryRow[entryCol - 1] || entryRow[entryCol - 1] && entryRow.filter(colItem => colItem.id === entryRow[entryCol - 1].id).length < 3) {
                        freeSpaceLeft = false
                    }
                }

                if (entryRow[entryCol + (entryWidth - 1) + 1] !== 0) {
                    if (!entryRow[entryCol + (entryWidth - 1) + 1] || entryRow[entryCol + (entryWidth - 1) + 1] && entryRow.filter(colItem => colItem.id === entryRow[entryCol + (entryWidth - 1) + 1].id).length < 3) {
                        freeSpaceRight = false
                    }
                }
            }

            for (const entryRowIndex of entryRowIndices) {
                const firstColIndex = day.merged[entryRowIndex].findIndex(e => e.id === entry.id)
                const lastColIndex = day.merged[entryRowIndex].findIndex((e, i) => {
                    return e.id === entry.id && (!day.merged[entryRowIndex][i + 1] || day.merged[entryRowIndex][i + 1].id !== entry.id)
                })

                if (freeSpaceLeft) {
                    day.merged[entryRowIndex][firstColIndex - 1] = entry
                    didChange = true
                }
                if (freeSpaceRight) {
                    day.merged[entryRowIndex][lastColIndex + 1] = entry
                    didChange = true
                }
            }
        }

        if (loop === 0) {
            didChange = true
        }

        loop++
    } while(didChange && loop < max)

    console.log(loop, max)

    /**
     * =================================================================
     * SET WIDTH AND COL PER ELEMENT
     * =================================================================
     */

    for (const entry of entries) {
        const day = entry.isFirstDay ? days.first : days.second
        const entryRowIndices = day.merged.map((e, i) => e.some(iE => iE.id === entry.id) ? i : '').filter(String)
        
        let calculatedCol = 0
        let calculatedWidth = 4

        const entryRows = entryRowIndices
            .map(entryRowIndex => {
                return day.merged[entryRowIndex].map((e, i) => e.id === entry.id ? i : '').filter(String)
            })
            .sort((a, b) => {
                return a.length - b.length
            })

        for (const entryRow of entryRows) {
            if (entryRow.length <= calculatedWidth && calculatedCol < entryRow[0]) {
                calculatedCol = entryRow[0]
            }

            if (entryRow.length < calculatedWidth) {
                calculatedWidth = entryRow.length
            }
        }

        for (const rowIndex in day.merged) {
            const row = day.merged[rowIndex]

            if (entryRowIndices.includes(parseInt(rowIndex))) {
                for (const colIndex in row) {
                    if (colIndex >= calculatedCol && colIndex < calculatedCol + calculatedWidth) {
                        row[colIndex] = entry
                    } else if (row[colIndex]?.id === entry.id) {
                        row[colIndex] = 0
                    }
                }
                for (let colIndex = calculatedCol; colIndex < calculatedCol + calculatedWidth; colIndex++) {
                    row[colIndex] = entry
                }
            }
        }
    }

    for (const entry of entries) {
        const day = entry.isFirstDay ? days.first : days.second
        const entryIndices = day.merged.map((e, i) => e.some(iE => iE.id === entry.id) ? i : '').filter(String)

        const calendarStart = `${(6 + ((entryIndices[0] * 15) / 60))}`.split('.')
        const calendarEnd = `${(6 + ((entryIndices[entryIndices.length - 1] * 15) / 60))}`.split('.')

        if (calendarEnd[1] === 0) {
            calendarEnd[0] = `${parseInt(calendarEnd[0]) - 1}`
            calendarEnd[1] = '75'
        }

        entry.calendarStart = { hour: calendarStart[0], minute: calendarStart[1] ? calendarStart[1].padEnd(2, 0) : '00' }
        entry.calendarEnd = { hour: calendarEnd[0], minute: calendarEnd[1] ? calendarEnd[1].padEnd(2, 0) : '00' }
        entry.col = 0
        entry.colWidth = 4

        const entryRows = entryIndices
            .map(entryRowIndex => {
                return day.merged[entryRowIndex].map((e, i) => e.id === entry.id ? i : '').filter(String)
            })
            .sort((a, b) => {
                return a.length - b.length
            })

        for (const entryRow of entryRows) {
            if (entryRow.length <= entry.colWidth && entry.col < entryRow[0]) {
                entry.col = entryRow[0]
            }

            if (entryRow.length < entry.colWidth) {
                entry.colWidth = entryRow.length
            }
        }

        entry.colsInUse = new Array(entry.colWidth).fill('').map((x, i) => entry.col + i)
    }

    for (const entry of entries) {
        const overlappingEntries = entries
            .filter(iE => {
                if (entry.id === iE.id) {
                    return false
                }

                return entry.colsInUse.some(col => iE.colsInUse.includes(col)) && entry.interval.overlaps(iE.interval)
            })
            .sort((a, b) => a.colsInUse.length - b.colsInUse.length)

        if (!overlappingEntries.length) {
            continue
        }

        if (overlappingEntries[0].colsInUse.length < entry.colsInUse.length) {
            entry.colsInUse = entry.colsInUse.filter(col => !overlappingEntries[0].colsInUse.includes(col))
            entry.col = entry.colsInUse[0]
            entry.colWidth = entry.colsInUse.length
        } else {
            overlappingEntries[0].colsInUse = overlappingEntries[0].colsInUse.filter(col => !entry.colsInUse.includes(col))
            overlappingEntries[0].col = overlappingEntries[0].colsInUse[0]
            overlappingEntries[0].colWidth = overlappingEntries[0].colsInUse.length
        }
    }

    return entries
}

export {
    getCalData
}