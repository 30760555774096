<template>
    <div class="main">
        <div class="inset">
            <h1 class="title">Belegung Meetingräume</h1>
        </div>
        <div class="calendar">
            <div class="calendar__title calendar__title--first">
                {{ days[0].toFormat('cccc') }}<span class="small">{{ days[0].day }}.{{ days[0].month }}.</span>
            </div>
            <div class="calendar__title calendar__title--second">
                {{ days[1].toFormat('cccc') }}<span class="small">{{ days[1].day }}.{{ days[1].month }}.</span>
            </div>

            <div
                v-for="hour of hours"
                :key="`time-${hour}`"
                class="calendar__time"
                :class="`calendar__time--${hour}`"
            >
                {{ hour }} Uhr
            </div>

            <div
                v-if="currentTimePercentage <= 100 && currentTimePercentage >= 0"
                class="calendar__time-indicator-rail"
            >
                <div
                    v-for="hour in hours"
                    :key="`time-${hour}`"
                    class="calendar__time-indicator"
                    :style="{ top: `${currentTimePercentage}%` }"
                ></div>
            </div>

            <div
                class="calendar__active"
                style="grid-area: first-6-00 / first-6-00 / first-20-75 / first-6-00;"
            >
            </div>

            <div
                class="
                    calendar__line
                    calendar__line--time
                "
            ></div>
            <div
                class="
                    calendar__line
                    calendar__line--center
                "
            ></div>
            <div
                class="
                    calendar__line
                    calendar__line--right
                "
            ></div>
            <div
                class="
                    calendar__line
                    calendar__line--bottom
                "
            ></div>
            <div
                v-for="hour of hours"
                :key="`line-${hour}`"
                class="
                    calendar__line
                    calendar__line--halfhour
                "
                :class="`calendar__line--${hour}`"
            ></div>
            <div
                v-for="hour of hours"
                :key="`line-${hour}`"
                class="
                    calendar__line
                    calendar__line--fullhour
                "
                :class="`calendar__line--${hour}`"
            ></div>

            <template
                v-for="entry of entries"
                :key="entry.title + entry.location + entry.start.time"
            >
                <CalendarEntry
                    :entry="entry"
                />
            </template>
        </div>

        <div class="inset">
            <div class="locations">
                <div class="locations__item locations__item--wettstein">Wettstein</div>
                <div class="locations__item locations__item--gundeli">Gundeli</div>
                <div class="locations__item locations__item--klybeck">Klybeck</div>
                <div class="locations__item locations__item--iselin">Iselin</div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import { DateTime } from 'luxon'
import { getCalData } from './helpers/ical'

export default {
    name: 'App',
    components: {
        CalendarEntry: defineAsyncComponent(() => import('./components/CalendarEntry'))
    },
    setup() {
        const days = ref([])
        const entries = ref([])
        const hours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
        const durationInMilliseconds = (DateTime.utc().set({ hour: (hours[hours.length - 1] + 1), minute: 0 }).diff(DateTime.utc().set({ hour: hours[0], minute: 0 }))).values.milliseconds
        const currentTime = ref(DateTime.now())

        const currentTimePercentage = computed(() => {
            const currentTimeInMillisecondsFromStart = currentTime.value.diff(DateTime.now().set({ hour: hours[0], minute: 0 })).values.milliseconds
            return currentTimeInMillisecondsFromStart / durationInMilliseconds * 100
        })

        const updateData = async () => {
            const today = DateTime.utc().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }).setLocale('de')
            const tomorrow = DateTime.utc().set({ hour: 6, minute: 0, second: 0, millisecond: 0 }).setLocale('de').plus({ day: 1 })

            days.value = [
                today,
                tomorrow
            ]

            entries.value = await getCalData(today)
        }

        const updateCurrentTime = () => {
            currentTime.value = DateTime.now()
        }

        updateData()
        setInterval(updateCurrentTime, 1000)
        setInterval(updateData, 60 * 1000)

        return {
            days,
            entries,
            hours,
            currentTimePercentage
        }
    }
}
</script>

<style lang="scss">
@use "sass:math";

$color-wettstein: #DFD9C0;
$color-gundeli: #C4A987;
$color-klybeck: #E2978B;
$color-iselin: #D2655B;

$zIndex: (
    activeDay: 1,
    lines: 2,
    entry: 3
);

@font-face {
    font-family: 'RiformaLL';
    src: url('./assets/fonts/RiformaLL-Medium.woff2') format('woff2'),
        url('./assets/fonts/RiformaLL-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'RiformaLL';
    src: url('./assets/fonts/RiformaLL-Regular.woff2') format('woff2'),
        url('./assets/fonts/RiformaLL-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

*, *::before, *::after {
    box-sizing: border-box;
}

body {
    font-family: 'RiformaLL';
    background-color: #000;
    color: #fff;
    margin: 0;
}

.main {
    max-width: 2040px;
    margin: 0 auto;
}

.inset {
    margin-left: 198px;
}

.title {
    font-size: 125px;
    font-weight: normal;
}

// $calendar-columns: 'first', 'second';
$calendar-height: 3145px;
$calendar-hours: 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20;
$calendar-header-row-height: 124px;
$calendar-row-height: math.div($calendar-height - $calendar-header-row-height, length($calendar-hours) * 4);

.calendar {
    height: 3145px;
    display: grid;
    grid-template-columns: 200px repeat(2, 1fr);
    grid-template-rows: $calendar-header-row-height repeat(length($calendar-hours) * 4, $calendar-row-height);
    gap: 0px 0px;
    grid-auto-flow: row;

    $gridTemplateAreas: '"tl first-title second-title" ';

    @each $calendar-hour in $calendar-hours {
        @for $i from 0 through 3 {
            $minute: '#{25 * $i}';
            @if str-length($minute) < 2 {
                $minute: '#{$minute}0'
            }
            $cols: '"time-#{$calendar-hour}-#{$minute} first-#{$calendar-hour}-#{$minute} second-#{$calendar-hour}-#{$minute}" ';
            $gridTemplateAreas: $gridTemplateAreas + $cols;
        }
    }

    grid-template-areas: #{$gridTemplateAreas};
}

.calendar__title {
    font-size: 75px;
    padding-bottom: 30px;

    &--first {
        grid-area: first-title;
    }
    &--second {
        grid-area: second-title;
    }

    .small {
        display: inline-block;
        font-size: 50px;
        margin-left: 0.6em;
    }
}

.calendar__active {
    position: relative;
    z-index: map-get($zIndex, 'activeDay');
    background-color: #3a3a3a;
}

.calendar__time {
    position: relative;
    font-size: 50px;

    @each $calendar-hour in $calendar-hours {
        &--#{$calendar-hour} {
            grid-area: time-#{$calendar-hour}-00;
        }
    }
}

.calendar__line {
    position: relative;
    z-index: map-get($zIndex, 'lines');

    &--halfhour {
        border-top: 2px dotted #fff;
    }
    @each $calendar-hour in $calendar-hours {
        &--#{$calendar-hour}.calendar__line--halfhour {
            grid-area: first-#{$calendar-hour}-50 / first-#{$calendar-hour}-50 / second-#{$calendar-hour}-50 / span 2;
        }
    }

    &--fullhour {
        border-top: 2px solid #fff;
    }
    @each $calendar-hour in $calendar-hours {
        &--#{$calendar-hour}.calendar__line--fullhour {
            grid-area: first-#{$calendar-hour}-00 / first-#{$calendar-hour}-00 / second-#{$calendar-hour}-00 / span 2;
        }
    }

    $lastCalendarHour: #{nth($calendar-hours, -1)};

    &--bottom {
        border-bottom: 2px solid #fff;
        grid-area: first-#{$lastCalendarHour}-75 / first-#{$lastCalendarHour}-75 / first-#{$lastCalendarHour}-75 / second-#{$lastCalendarHour}-75;
    }

    &--time,
    &--center,
    &--right {
        border-right: 2px solid #fff;
    }
    &--time {
        grid-area: time-6-00 / time-6-00 / time-#{$lastCalendarHour}-75 / time-6-00;
    }
    &--center {
        grid-area: first-6-00 / first-6-00 / first-#{$lastCalendarHour}-75 / first-6-00;
    }
    &--right {
        grid-area: second-6-00 / second-6-00 / second-#{$lastCalendarHour}-75 / second-6-00;
    }
}

.calendar__entry {
    position: relative;
    overflow: hidden;
    z-index: map-get($zIndex, 'entry');
    color: #000;
    margin: 2px 0 0;

    &--wettstein {
        background-color: $color-wettstein;
    }
    &--gundeli {
        background-color: $color-gundeli;
    }
    &--klybeck {
        background-color: $color-klybeck;
    }
    &--iselin {
        background-color: $color-iselin;
    }

    &--col-1 {
        left: 25%;
    }
    &--col-2 {
        left: 50%;
    }
    &--col-3 {
        left: 75%;
    }

    &--w-1 {
        width: 25%;

        &.calendar__entry--col-3 {
            width: calc(25% - 2px);
        }
    }
    &--w-2 {
        width: 50%;

        &.calendar__entry--col-2 {
            width: calc(50% - 2px);
        }
    }
    &--w-3 {
        width: 75%;

        &.calendar__entry--col-1 {
            width: calc(75% - 2px);
        }
    }
    &--w-4 {
        width: calc(100% - 2px);
    }
}

.calendar__entry-inner {
    position: absolute;
    top: 15px;
    right: 15px;
    left: 15px;
    height: 100%;

    .calendar__entry--sm &,
    .calendar__entry--xs & {
        top: 0px;
        right: 5px;
    }

    .calendar__entry--xs & {
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 30px;
    }
}

.calendar__entry-top {
    .calendar__entry--xs &,
    .calendar__entry--sm & {
        display: flex;
        align-items: center;
        white-space: nowrap;
        gap: 30px;
    }
}

.calendar__entry-time {
    order: 0;
}

.calendar__entry-location {
    order: 1;
}

.calendar__entry-time,
.calendar__entry-location {
    font-size: 42px;

    .calendar__entry--sm & {
        display: inline-block;
    }
}

.calendar__entry-title {
    font-size: 46px;
    font-weight: 500;
    margin-top: 5px;
    word-break: break-word;

    .calendar__entry--xs & {
        margin-top: -3px;
        word-break: normal;
    }
    .calendar__entry--sm & {
        display: block;
        margin-top: -5px;
    }
}

.calendar__time-indicator-rail {
    grid-area: time-6-00/time-6-00/time-20-75/time-6-00;
    position: relative;
}

.calendar__time-indicator {
    width: 0;
	height: 0;
	border-width: 22px 0 22px 30px;
	border-color: transparent transparent transparent #FFFFFF;
	border-style: solid;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -22px;
}

.locations {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 38px;
    margin: 100px 0 0;
}

.locations__item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: 500;
    height: 125px;
    color: #000;

    &--wettstein {
        background-color: $color-wettstein;
    }
    &--gundeli {
        background-color: $color-gundeli;
    }
    &--klybeck {
        background-color: $color-klybeck;
    }
    &--iselin {
        background-color: $color-iselin;
    }
}
</style>
